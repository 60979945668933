import { getSkill, Skill, skills } from "../skills/config";
import Transit from "./../../images/transit.png";
import homdao from "./../../images/homdao.png";
import Crypto from "./../../images/crypto-tracker.png";
import Diamond from "./../../images/diamond-hands.png";
import Coin from "./../../images/coin-flip.png";
import Mobile from "./../../images/wallet.png";
import OHO from "./../../images/oho1.png";

export type Project = {
  name: string,
  description: string,
  image: any,
  url: string,
  github: string,
  technologies: Skill[],
}

export const projects: Project[] = [
  {
    name: "HOM DAO",
    description: "HOM DAO's mission is to help provide safe, sustainable housing for everyone.",
    image: homdao,
    url: "https://homdao.io/",
    github: "https://github.com/danilo0917/HOM-DAO",
    technologies: getSkill(["Open-Source Contribution", "react", "typescript", "css", "solidity"])
  },
  {
    name: "OHO Chain",
    description: "OHO Blockchain is a fast, scalable, smart contract capable, EVM compatible, eco-friendly and secure PoA platform with 3-second finality and low fees. It is fully equipped with a variety of universal & user-friendly tools.",
    image: OHO,
    url: "http://oho.ai/",
    github: "https://github.com/danilo0917/OHO-DEFI",
    technologies: getSkill(["react", "typescript", "nodejs", "socket.io", "moralis", "solidity"])
  },
  {
    name: "Transit Swap Bridge",
    description: "Transit Swap is a one-stop cross-chain swap platform aggregating different DEXs across different networks.",
    image: Transit,
    url: "https://swap.transit.finance/",
    github: "https://github.com/danilo0917",
    technologies: getSkill(["html", "css", "javascript", "typescript", "solidity", "moralis"])
  },
  // {
  //   name: "Coin Flip",
  //   description: "Multiplayer Coin Flip game using smart contracts and chainlink VRF to determine a winner in decentralized way.",
  //   image: Coin,
  //   url: "https://projects.tijan.dev/coin-flip",
  //   github: "https://github.com/0xTijan/coin-flip",
  //   technologies: getSkill(["react", "solidity", "hardhat", "typescript", "chainlink", "moralis"])
  // },
  // {
  //   name: "Diamond Hands",
  //   description: "Funny blockchain game that prevents you to panic sell your cryptos by locking them in smart contract.",
  //   image: Diamond,
  //   url: "https://projects.tijan.dev/diamond-hands",
  //   github: "https://github.com/0xTijan/diamond-hands",
  //   technologies: getSkill(["solidity", "hardhat", "react", "javascript", "moralis"])
  // },
  // {
  //   name: "Crypto Tracker",
  //   description: "Users can see all statistics about top 250 cryptos and create their own personal portfolio.",
  //   image: Crypto,
  //   url: "https://projects.tijan.dev/crypto-tracker",
  //   github: "https://github.com/0xTijan/crypto-tracker",
  //   technologies: getSkill(["react", "javascript", "moralis", "css", "html", "git", "npm"])
  // },
  // {
  //   name: "Mobile Wallet",
  //   description: "Shows on-chain stats off user's address. User can add personal contacts for easy assets transfer.",
  //   image: Mobile,
  //   url: "",
  //   github: "https://github.com/0xTijan/mobile-wallet",
  //   technologies: getSkill(["react native", "javascript", "moralis", "css", "npm"])
  // },
];