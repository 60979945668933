import React from "react";
import { Button } from "@chakra-ui/react";
import { GrLocation } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import { AiFillGithub, AiFillTwitterCircle, AiOutlineArrowRight } from "react-icons/ai";
import { BsTelegram, BsGlobe2 } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { FaDiscord } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import "./Contact.css";

export type Contact = {
  name: string,
  info: string,
  icon: React.ReactElement,
  url: string,
}

export const contacts: Contact[] = [
  // {
  //   name: "TELEGRAM:",
  //   info: "@daniel029112",
  //   icon: <BsTelegram className="contact-icon" />,
  //   url: "https://t.me/daniel029112",
  // },
  // {
  //   name: "DISCORD:",
  //   info: "Crypto_Dash#9531",
  //   icon: <FaDiscord className="contact-icon" />,
  //   url: "https://discord.com",
  // },
  // {
  //   name: "GitHub:",
  //   info: "@Danio0917",
  //   icon: <AiFillGithub className="contact-icon" />,
  //   url: "https://github.com/danilo0917",
  // },
  // {
  //   name: "LOCATION & ZONE:",
  //   info: "Miami, FL, USA (GMT-4)",
  //   icon: <IoLocationOutline className="contact-icon" />,
  //   url: "https://www.timeanddate.com/time/zones/edt",
  // },
];

const Contact = () => {
  return(
    <div className="bg-contact">
      <div className="contact-header">
        <p className="heading">Contact Me</p>
        <p className="text">If you have any questions or want to hire me, please chat with me:</p>
      </div>

      <div className="contact-grid">
        {contacts.map((contact: Contact) => {
          return(
            <a key={uuidv4()} href={contact.url} target="_blank" rel="noreferrer">
              <div className="contact-section">
                {contact.icon}
                <div className="contact-text-div">
                  <p className="contact-title">{contact.name}</p>
                  <p className="contact-details">{contact.info}</p>
                </div>
              </div>
            </a>
          );
        })}
      </div>

      {/* <div className="button-container">
        <a href="mailto:contactme@tijan.dev">
          <Button size='lg' rightIcon={<AiOutlineArrowRight />} colorScheme="blue" variant='solid' className="contact-btn">
            Contact Me
          </Button>
        </a>
      </div> */}
    </div>
  );
};

export default Contact;